import { Button, Text, Img } from "components";
import { useTranslation } from "react-i18next";
import { changeToken } from "redux/reducers/token";
import { useDispatch } from "react-redux";
import { Link, useLocation} from "react-router-dom";
import { useEffect } from "react";
const PayoutDetailApprove = ({ closepopup }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none sm:p-4 w-fit h-fit m-auto">
        <div className="relative my-auto mx-auto w-[400px] sm:w-full max-w-400 sm:my-0">
          {/*content*/}
          <div className="border-0 shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            {/*header*/}
            <div className="p-[30px] relative">
              <button
                className="w-6 h-6 ml-auto bg-transparent border-0 text-black opacity-75 float-right text-3xl leading-none font-semibold outline-none focus:outline-none absolute right-0 top-0 mt-3 mr-3"
                onClick={() => closepopup(false)}
              >
                <Img src="images/modal_close.svg" alt="close" />
              </button>
              {/* <Text
                className="font-proximanova text-black_900 mb-2"
                variant="h3"
                as="h3"
              >
                {t("Please ensure your payout details are approved")}
              </Text> */}
              <Text
                className="font-proximanova text-black_900 mb-2"
                variant="body1"
                as="p"
              >
                Before you can create a lesson, please ensure your payout
                details are approved. If you haven’t updated your payout
                information yet, kindly navigate to the sidebar, complete the{" "}
                <Link to="/payout-detail" className="text-teal_500 cursor-pointer" onClick={()=>closepopup(false)} >Payout Details</Link>
              </Text>
            </div>
          </div>
        </div>
      </div>
      <div
        className="opacity-25 fixed inset-0 z-40 bg-black"
        onClick={() => closepopup(false)}
      ></div>
    </>
  );
};
export { PayoutDetailApprove };
