import React from "react";
import { Text } from "components";

const ContentBlock = ({title, content}) => {
  return (
    <div className="max-w-6xl mx-auto px-8 py-12">
      <div className="text-center">
        {/* Headline Section */}
        <Text
          as="h2"
          variant="h2"
          className="font-trajanpro font-bold text-3xl text-red-500 mb-4"
        >
          {title}
        </Text>

        {/* Introductory Paragraph */}
        <Text
  variant="p"
  className="mt-4 text-black leading-relaxed text-lg"
  dangerouslySetInnerHTML={{
    __html: content.replace(/\n/g, "<br />")
  }}
/>

      </div>
    </div>
  );
};

export default ContentBlock;