import React from "react";
import { Button, Img, Text, PasswordInput } from "components";
import { useTranslation } from "react-i18next";
import { changeChatbox, currentChatbox } from "redux/reducers/chatbox";
import { useDispatch, useSelector } from "react-redux";

const ParentPasswordModal = ({ closepopup }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currentChat = useSelector(currentChatbox);

  const toggleChatBox = () => {
    dispatch(changeChatbox(!currentChat));
  };
  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none sm:px-4 w-fit h-fit m-auto">
        <div className="relative w-auto my-auto mx-auto max-w-460 min-w-[460px] sm:min-w-[290px]">
          {/*content*/}
          <div className="border-0 shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            {/*header*/}
            <div className="p-6 relative">
              <Text
                className="font-trajanpro text-black_900 text-center mb-3 mt-3"
                variant="h2"
                as="h2"
              >
                {t("createParentPassword")}
              </Text>
              <Text
                className="font-proximanova text-black_900 text-center mb-3"
                variant="body1"
                as="p"
              >
                {t(
                  "setYourPasswordHereParentPasswordMustBeDifferentFromLoginPasswords"
                )}
              </Text>
              <button
                className="w-6 h-6 ml-auto bg-transparent border-0 text-black opacity-75 float-right text-3xl leading-none font-semibold outline-none focus:outline-none absolute right-0 top-0 mt-3 mr-3"
                onClick={() => closepopup(false)}
              >
                <Img src="images/modal_close.svg" alt="close" />
              </button>
            </div>
            <div className="relative px-6 pb-6 flex-auto">
              <PasswordInput label={t("password")} required={true} />
              <PasswordInput label={t("confirmPassword")} required={true} />
              <Button
                className="mt-4 ease-linear transition-all duration-150 w-full"
                variant={"FillRed900"}
                size="md"
                onClick={() => closepopup(false)}
              >
                {t("submit")}
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div
        className="opacity-25 fixed inset-0 z-40 bg-black"
        onClick={toggleChatBox}
      ></div>
    </>
  );
};
export { ParentPasswordModal };