import { Button, Text } from "components";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { currentUserData } from "redux/reducers/token";

const SubjectBanner = ({departmentDetails}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const userData = useSelector(currentUserData);

  // Smooth scroll to the banner on component mount
  useEffect(() => {
    document.getElementById("home")?.scrollIntoView({ behavior: "smooth", block: "center" });
  }, []);

  return (
    <div
      id="home"
      className="hm-sec1 homebannerSec relative h-[668px] sm:h-[450px]"
      style={{
        backgroundImage: departmentDetails.header_image_url? `url('${departmentDetails.header_image_url}')` : "url('https://i.postimg.cc/qqCn0VgM/ifn1wf8h3qiw2w5i9xlo.png')",
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      {departmentDetails.id && <div className="bannerContent absolute inset-0 m-auto max-w-[1015px] w-full text-center flex flex-col justify-center items-center smx:px-[30px]">
        <h1 className="text-[42px] md:text-4xl smx:text-3xl sm:text-xl text-white_A700 font-semibold leading-[50px] w-full">
          {departmentDetails.name}
        </h1>
        <Text
          className="mt-3 mb-[40px] text-white_A700 font-semibold mx-auto max-w-[559px] w-full smx:text-lg sm:text-sm"
          variant="h5"
        >
          {departmentDetails.description}
        </Text>
        <Button
          className="cursor-pointer font-semibold min-w-[200px] text-base text-center text-white_A700"
          size="md"
          variant="FillRed900"
          onClick={() => navigate(userData ? userData.roleType == 'student' ? "/dashboard-student" : "/tutor-profile" : "/signup")}
        >
          Get Started
        </Button>
      </div>}
    </div>
  );
};

export default SubjectBanner;