import { Button, Img, Line, Text } from "components";
// import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { currentToken, currentUserData } from "redux/reducers/token";
import {changeChatbox} from "redux/reducers/chatbox";
import { changeSnackbar } from "redux/reducers/snackbar";
import { useEffect } from "react";

const StaticFooter = () => {
  // const { t } = useTranslation();
  //for scroll
  const dispatch = useDispatch();
  const token = useSelector(currentToken);
  const navigate = useNavigate();
  const userData = useSelector(currentUserData);
  const scrollToElement = (element) => {
    const currentUrl = window.location.pathname;
    if(currentUrl === element){
      let url = element === "/" ? "home" : element.slice(1);
      const input = document.getElementById(url);
      input.scrollIntoView({ behavior: "smooth", block: "center" });
    }else{
      navigate(element)
    }  
  };

  const handleClick = () => {
    if (token) {
      dispatch(changeChatbox(true));
    } else {
      navigate("/login-student");
    }
  };

  return (
    <>
      <div
        className="footer bg-black_000 pt-[60px] sm:pt-6"
        style={{
          backgroundImage: "url('images/footer-bg-img.webp')",
          backgroundSize: "cover",
        }}
      >
        <div className="max-w-[1140px] pl-[15px] pr-[15px] md:w-full mx-auto">
          {/* <div className="flex flex-wrap items-center flex-row -mx-[15px] h-full relative gap-y-[30px]">
            <div className="w-[80%] sm:w-full px-[15px]">
              <Text
                className="text-[32px] block text-white_A700 font-bold"
                variant={"h3"}
              >
                Book a free chat with one of our tutors
              </Text>
            </div>
            <div className="w-[20%] sm:w-full px-[15px]">
              <Button
                className="cursor-pointer font-semibold w-full text-base text-center text-white_A700"
                size="md"
                variant="FillRed900"
                onClick={handleClick}
              >
                Quick Chat
              </Button>
            </div>
          </div> */}
          {/* <Line className="bg-gray_200 mt-[40px] mb-[50px] h-px w-[100%]" /> */}
          <div className="flex flex-wrap items-start flex-row -mx-[15px] h-full relative gap-y-[30px]">
            <div className="w-[35%] smx:w-full px-[15px]">
              <Img
                className="footerlogo w-[230px] h-[50px]"
                src="images/footer-logo.png"
                alt="Footer logo"
              />
              <Text className="mt-[37px] mb-[28px] block text-white_A700 font-normal text-sm">
              Enabling students to achieve academic excellence through world-class tutoring, personalised learning and immersive technologies
              </Text>
              <div className="flex items-center">
                <a target="_blank" rel="noopener noreferrer"  href="https://www.facebook.com/profile.php?id=61550477155173">
                <Img
                  className="mr-[34px] cursor-pointer w-5 h-5 object-scale-down"
                  src="images/facebook.svg"
                  alt="facebook"
                />
                </a>
                {/* <a 
                target="_blank"
                 rel="noopener noreferrer"
                   href="https://www.linkedin.com/company/battersea-house/?viewAsMember=true"
                   >
                <Img
                  className="mr-[34px] w-5 h-5 object-scale-down"
                  src="images/linkedIn.svg"
                  alt="linkedIn"
                /></a> */}
                <a target="_blank" rel="noopener noreferrer"  href="https://www.instagram.com/battersea.house/">
                <Img
                  className="mr-[34px] cursor-pointer w-5 h-5 object-scale-down"
                  src="images/insta.svg"
                  alt="insta"
                /></a>
                <a target="_blank" rel="noopener noreferrer"  href="https://www.google.com/url?q=https://www.youtube.com/channel/UCGnIRu70r7t90-HpZZfszLw&sa=D&source=docs&ust=1703742154400515&usg=AOvVaw3auozi9jsFUllGh3cFpc4t" >
                <Img
                  className="mr-[0px] cursor-pointer  w-5 h-5 object-scale-down"
                  src="images/youtube.svg"
                  alt="Youtube"
                />
                </a>
              </div>
            </div>
            <div className="w-[25%] sm:w-full px-[15px] pl-[100px] smx:pl-[15px]">
              <Text className="mb-[20px] block text-white_A700 font-bold text-sm">
                Quick Links
              </Text>
              <ul className="pl-0">
                <li className="cursor-pointer font-normal text-white_A700 text-base mb-[20px]" onClick={()=>{scrollToElement("/")}}>
                  Home
                </li>
                <li className="cursor-pointer font-normal text-white_A700 text-base mb-[20px]" onClick={()=>{scrollToElement("/find-tutor")}}>
                  Find a Tutor
                </li>
                {/* <li className="cursor-pointer font-normal text-white_A700 text-base mb-[20px]">
                  Departments
                </li> */}
                <li className="cursor-pointer font-normal text-white_A700 text-base mb-[20px]">
                  <Link to="/purchase-plan" className="cursor-pointer">Subscription</Link>
                </li>
              </ul>
            </div>
            <div className="w-[17%] smx:w-[25%] sm:w-full px-[15px] sm:-mt-[30px]">
              <Text className="mb-[20px] block text-white_A700 font-bold text-sm sm:hidden">
                &nbsp;
              </Text>
              <ul className="pl-0">
                <li className="cursor-pointer font-normal text-white_A700 text-base mb-[20px]">
                  <Text className="cursor-pointer normal-case" onClick={()=>{
                     if(Object.keys(userData)?.length === 0){
                       navigate("/tutorsignup")
                     }else if(userData?.roleType === "tutor") {
                      dispatch(changeSnackbar({ isOpen: true, message: "You are already logged in as a tutor", state: "error" }));
                     }else if (userData?.roleType === "student") {
                      dispatch(changeSnackbar({ isOpen: true, message: "You are logged in as student, you cannot use same email to create tutor account.", state: "error" }));
                      }
                  }} >Become a Tutor</Text>
                </li>
                <li className="cursor-pointer font-normal text-white_A700 text-base mb-[20px]" onClick={()=>{scrollToElement("/about-us")}}>
                About us
                </li>
              </ul>
            </div>
            <div className="w-[23%] smx:w-[50%] sm:w-full px-[15px] pl-[90px] sm:px-[15px] sm:-mt-5">
              <Text className="mb-[20px] block text-white_A700 font-bold text-sm">
                Help
              </Text>
              <ul className="pl-0">
                <li className="cursor-pointer font-normal text-white_A700 text-base mb-[20px]" onClick={()=>{scrollToElement("/faq")}}>
                  FAQ
                </li>
                <li className="cursor-pointer font-normal text-white_A700 text-base mb-[20px]" onClick={()=>{scrollToElement("/contact-us")}}>
                Contact Us
                </li>
              </ul>
            </div>
          </div>
          <Line className="bg-gray_300 mt-[46px] sm:mt-2 h-px w-[100%]" />
          <div className="flex flex-wrap items-center sm:flex-col flex-row -mx-[15px] h-full relative py-6 gap-y-[15px]">
            <div className="w-[50%] sm:w-full px-[15px]">
              <Text className="block text-gray_600 font-semibold text-base sm:text-center">
                &copy; { new Date().getFullYear() }, All Rights Reserved
              </Text>
            </div>
            <div className="w-[50%] sm:w-full px-[15px] flex justify-end sm:justify-center">
            <Link
                to="/cookie-policy"
                className="cursor-pointer font-semibold text-gray_600 text-base">
                Cookie Policy
              </Link>
              <Link
                to="/privacy-policy"
                className="cursor-pointer font-semibold text-gray_600 text-base ml-[40px]">
                Privacy Policy
              </Link>
              <Link
                to="/terms-condition"
                className="cursor-pointer font-semibold text-gray_600 text-base ml-[40px]">
                Terms of Use
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default StaticFooter;