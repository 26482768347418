import React, { useEffect } from "react";
import { Button, Img, Text, PasswordInput, Input } from "components";
import { useTranslation } from "react-i18next";
import { changeChatbox, currentChatbox } from "redux/reducers/chatbox";
import { useDispatch, useSelector } from "react-redux";
import { currentUserData, changeToken } from "redux/reducers/token";
import { API } from "studentEnum";
import globalRequest from "global-modules/globalRequest";
import { changeSnackbar } from "redux/reducers/snackbar";
import TokenRefresh from "Helpers/TokenRefresh";

const ParentPortalModal = ({ closepopup }) => {
  
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currentChat = useSelector(currentChatbox);
  const userData = useSelector(currentUserData);
  const [password, setPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");

  // Error 
  const [passwordError, setPasswordError] = React.useState("");
  const [confirmPasswordError, setConfirmPasswordError] = React.useState("");

  const toggleChatBox = () => {
    dispatch(changeChatbox(!currentChat));
  };
 
  const handleError = () => {
    let error = false;
    if (password === "") {
      setPasswordError("Please enter password");
      error = true;
    }
    if ( userData?.parent_password === 0 && confirmPassword === "") {
      setConfirmPasswordError("Please enter confirm password");
      error = true;
    }
    if(userData?.parent_password === 0 && confirmPassword !== password){
      setConfirmPasswordError("Password and confirm password does not match");
      error = true;
    }
    if(password!=="" && password.length<8){
      setPasswordError("Password must be 8 characters long");
      error = true;
    }
    if(userData?.parent_password === 0 && confirmPassword!=="" && confirmPassword.length<8){
      setConfirmPasswordError("Confirm password must be 8 characters long");
      error = true;
    }
    return error;
  }
  
  const handleSubmit = async () => {
    if (handleError()) {
      return false ;
    }
    let url;
    let data = {
      password: password,
    };
    if (userData?.parent_password === 0) {
      url = API.PARENT_AUTH.Set_Parent_Password;
      data.confirmPassword = confirmPassword;
    } else {
      url = API.PARENT_AUTH.Check_Parent_Password;
    }
    let responce = await globalRequest('post', url , data , {} , true , true);
    if(responce?.status==="SUCCESS"){
      dispatch(changeSnackbar({ isOpen: true, message: responce?.message, state: responce?.status==="SUCCESS"? "success" : "error" }));
      await TokenRefresh();
      dispatch(changeToken());
      closepopup(false);
      toggleChatBox();
    }else{
      dispatch(changeSnackbar({ isOpen: true, message: responce?.message, state: "error" }));
    }
}


  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none sm:px-4 w-fit h-fit m-auto">
        <div className="relative w-auto my-auto mx-auto max-w-460 min-w-[460px] sm:min-w-[290px]">
          {/*content*/}
          <div className="border-0 shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            {/*header*/}
            <div className="p-6 relative">
              <Text
                className="font-trajanpro text-black_900 text-center mb-3 mt-3"
                variant="h2"
                as="h2"
              >
                {t("parentPassword")}
              </Text>
              <Text
                className="font-proximanova text-black_900 text-center mb-3"
                variant="body1"
                as="p"
              >
                {t("You can use the parent portal to message tutors from your parental profile. Your child will not be able to see these messages. Once you exit the messaging platform, you will automatically return to your student profile.")}
              </Text>
              <button
                className="w-6 h-6 ml-auto bg-transparent border-0 text-black opacity-75 float-right text-3xl leading-none font-semibold outline-none focus:outline-none absolute right-0 top-0 mt-3 mr-3"
                onClick={() => closepopup(false)}
              >
                <Img src="images/modal_close.svg" alt="close" />
              </button>
            </div>
            <div className="relative px-6 pb-6 flex-auto">
              <Input label={t("StudentId")} required={true} value={userData?.student_id} disabled={true} />
              <PasswordInput label={t("Password")} required={true} error={passwordError!==""} errorText={passwordError} onChange={(e)=>{
                setPasswordError("");
                setPassword(e.target.value)
              }}  />
              { userData?.parent_password===0 ? <PasswordInput label={t("Confirm Password")} required={true} error={confirmPasswordError!==""} errorText={confirmPasswordError} onChange={(e)=>{
                setConfirmPasswordError("");
                setConfirmPassword(e.target.value)
              }} />: null}
              <Button
                className="mt-4 ease-linear transition-all duration-150 w-full"
                variant={"FillRed900"}
                size="md"
                onClick={handleSubmit}
              >
                {t("submit")}
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div
        className="opacity-25 fixed inset-0 z-40 bg-black"
        onClick={() => closepopup(false)}
      ></div>
    </>
  );
};
export { ParentPortalModal };