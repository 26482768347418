import React, { useState } from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import Header from "components/Header";
import { HeaderAfterLogin } from "components/HeaderAfterLogin";
import StudentHeader from "components/StudentHeader";
import { currentToken, currentUserData } from "redux/reducers/token";

const MainHeader = () => {
  const token = useSelector(currentToken);
  const userData = useSelector(currentUserData);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  // Determine header based on user role and state
  const renderHeader = () => {
    if (token) {
      switch (userData.roleType) {
        case "student":
          return <StudentHeader />;
        default:
          return userData.regStep !== 5 ? <Header /> : <HeaderAfterLogin />;
      }
    }
    return <Header />;
  };

  // Toggle dropdown menu visibility
  const toggleDropdown = () => setIsDropdownOpen(!isDropdownOpen);

  return (
    <>
      {/* Conditionally Render Header */}
      {renderHeader()}
    </>
  );
};

export default MainHeader;
