import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Img, Line, Text, Button } from "components";
import { Menu, MenuItem } from "@mui/material";
import { ParentPasswordModal } from "modals/ParentPasswordModal";
import { ChangePasswordModal } from "modals/ChangePasswordModal";
import { LogoutModal } from "modals/LogoutModal";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { changeSnackbar } from "redux/reducers/snackbar";
import { currentUserData, changeToken } from "redux/reducers/token";
import { ParentPortalModal } from "modals/ParentPortalModal";
import { changeChatbox, currentChatbox  ,changeChatObject} from "redux/reducers/chatbox";
import globalRequest from "global-modules/globalRequest";
import API from "tutorEnum";
import {calculateAge} from "Helpers";
import  socket  from "../../socket";

const StudentHeader = (props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const currentChat = useSelector(currentChatbox);
  const [userAge, setUserAge] = React.useState(null);
  const [ParentPasswordOpen, setParentPasswordOpen] = React.useState(null);
  const [ParentPortalOpen, setParentPortalOpen] = React.useState(null);
  const [ChangePasswordOpen, setChangePasswordOpen] = React.useState(null);
  const [LogoutModalOpen, setLogoutModalOpen] = React.useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isHovered, setIsHovered] = useState(false);
  const [hamburger, setHamburger] = useState(false);
  const [url, setUrl] = useState("");
  const [isOpenDepartment, setIsOpenDepartment] = useState(false);
  const [departments, setDepartments] = React.useState([]);
  const [departmentsUrls, setDepartmentsUrls] = React.useState([]);
  const userData = useSelector(currentUserData);
  const [active, setActive] = React.useState(null);
  const { pathname } = useLocation();
  const { slug } = useParams();
  const [isreceiveCount, setReceiveCount] = React.useState(0);
  const handleToggleDepartment = () => {
    setIsOpenDepartment(!isOpenDepartment);
  };
  const handlehamburger = () => {
    setHamburger(!hamburger);
  };
  const hamburgerStyles = !hamburger ? "" : "is-active";
  const navStyles = !hamburger
    ? "md:hidden"
    : "md:flex absolute flex-col items-start bg-white z-[99999] right-0 top-full md:w-screen md:h-screen p-4";
  const isMaxDepartment = !isOpenDepartment ? "md:hidden" : "md-show";
  const toggleChatBox = () => {
    // setHamburger(!hamburger);
    dispatch(changeChatbox(!currentChat));
    // Add active class
    const chatDiv = document.querySelector('.your-div-selector'); 
    if (chatDiv.classList.contains("your-div-selector")) {
      chatDiv.classList.add("active");
      setActive('');
    } else {
      setActive(pathname);
      chatDiv.classList.remove("active");
    }
  };
  const closeChats = async () => {
    dispatch(
      changeChatObject({
        tutorId: 0,
        isSingle: false,
        tutor: null,
      })
    );
    dispatch(changeChatbox(false));
  };
  const handleMouseEnter = () => {
    setIsHovered(true);
  };
  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleProfile = () => {
    const newTab = window.open("/student-profile", "_blank");
    newTab.focus();
    setAnchorEl(null);
  };
  const handleMySubscription = () => {
    navigate("/my-subscription");
    setAnchorEl(null);
  };
  const handleParentPassword = () => {
    // setParentPasswordOpen(true);
    setParentPortalOpen(true);
    setAnchorEl(null);
  };
  const handleChangePassword = () => {
    setChangePasswordOpen(true);
    setAnchorEl(null);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const getDepartment = async () => {
    const response = await globalRequest(
      "get",
      API.POST.GET_ALL_DEPARTMENT,
      {},
      {},
      true,
      true
    );
    setDepartments(response.data);

    let urls = [];

    for(const cat of response.data){
      urls.push(`/${cat.slug}`);
      for(const subCat of cat.subCategory){
        urls.push(`/${cat.slug}/${subCat.slug}`);
      }
    }

    setDepartmentsUrls(urls);
  };

  React.useEffect(() => {
    getDepartment();
    socket?.on('recieveCount', (data) => {
      setReceiveCount(data.count);
    });
  }, []);

  const updateDepartment = async () => {
    const departmentBox = departments.map((department) => {
      if (department.subCategory.find((subCategory) => subCategory.slug === slug)) {
        return {
          ...department,
          subCategory: department.subCategory.map((subCategory) => {
            if (subCategory.slug === slug) {
              return {
                ...subCategory,
                isActive: true,
              };
            } else {
              return {
                ...subCategory,
                isActive: false,
              };
            }
          }),
          isActive: true,
        };
      } else {
        return {
          ...department,
          subCategory: department.subCategory.map((subCategory) => {
            return {
              ...subCategory,
              isActive: false,
            };
          }),
          isActive: false,
        };
      }
    })
    setDepartments(departmentBox);
  }


  React.useEffect(() => {
    if (isHovered) {
      updateDepartment ();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isHovered, slug]);

  React.useEffect(() => {
    if (
      LogoutModalOpen === true ||
      ChangePasswordOpen === true ||
      ParentPasswordOpen === true ||
      ParentPortalOpen === true
    ) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }
    return () => {
      document.body.classList.remove("overflow-hidden");
    };
  }, [
    LogoutModalOpen,
    ChangePasswordOpen,
    ParentPasswordOpen,
    ParentPortalOpen,
  ]);
  React.useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === "Escape") {
        setParentPasswordOpen(false);
        setParentPortalOpen(false);
        setChangePasswordOpen(false);
        setLogoutModalOpen(false);
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  useEffect(() => {
    if (userData && userData?.dob) {
      let age = calculateAge(userData?.dob)
      setUserAge(age);
    } else {
      setUserAge(userData?.age);
    }
  }, [userData]);

  useEffect(() => {
    setActive(pathname);
  }, [pathname]);

  return (
    <>
      {/* props.className  */}
      <header
        className={
          "bg-white_A700 flex md:flex-col flex-row md:gap-5 items-center justify-center md:px-4 w-full fixed top-0 z-[39]"
        }
      >
        <div className="w-full max-w-[1110px] flex justify-between">
          <div className="student-header-left">
            <Img
              onClick={() => navigate("/dashboard-student")}
              className="my-2.5 object-cover w-[230px] h-[50px] md:hidden"
              src="images/img_batterseahouse1.png"
              alt="batterseahouse"
            />
            <Img
              className=" my-2.5 object-cover w-[48px] h-[48px] hidden md:block"
              src="images/img_batterseahouse-logo.png"
              alt="batterseahouse"
            />
          </div>
          <div className="student-header-right flex items-center">
            <button
              className={`${hamburgerStyles} hamburger hamburger--slider hidden md:flex`}
              type="button"
              onClick={handlehamburger}
            >
              <span className="hamburger-box">
                <span className="hamburger-inner"></span>
              </span>
            </button>
            <div
              className={`flex sm:flex-col flex-row gap-6 items-start justify-start md:ml-[0] ml-auto w-auto sm:w-full ${navStyles}`}
            >
              <div
                className={`flex flex-row gap-2 items-center justify-start cursor-pointer h-full md:h-auto a-link find-tutor ${
                  active === "/find-tutor" ? "active" : ""
                }`}
                onClick={() => navigate("/find-tutor")}
              >
                <Text className="text-black_900 text-sm hover:text-red_900 flex items-center flex-row gap-2">
                  <Img
                    className="h-6 w-6"
                    src="images/img_user_red.svg"
                    alt="user"
                  />
                  {t("findATutor")}
                </Text>
              </div>
              <div
                className={`${
                  isHovered
                    ? "flex flex-row md:flex-col gap-2 items-center md:items-start justify-start cursor-pointer h-full md:h-auto department-parent show"
                    : "flex flex-row md:flex-col gap-2 items-center md:items-start justify-start cursor-pointer h-full md:h-auto department-parent hide"
                } ${departmentsUrls.includes(active) ? "active" : ""}`}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
              >
                <Text
                  className="text-black_900 text-sm hover:text-red_900 flex items-center flex-row gap-2"
                  onClick={handleToggleDepartment}
                >
                  <Img
                    className="h-6"
                    src="images/icon-department-red.svg"
                    alt="mail"
                  />
                  {t("department")}
                </Text>

                <div
                  className={`department-box w-auto bg-white_A700 py-0 absolute md:relative top-[70px] md:top-auto z-50 show ${isMaxDepartment}`}
                >
                  <div className="flex flex-col gap-0 border-r md:border-none border-gray_200">
                    { Array.isArray(departments) &&
                      departments?.map((department, index) => (
                        <div
                          className={`flex flex-row md:flex-col items-center justify-between w-full gap-4 department-row py-2 px-4 relative ${ active === `/${department.slug}` ? "active" : ""}`}
                          key={`department-${index}`}
                        >
                          <Text 
                            onClick={() =>
                              navigate(
                                `/${department.slug}`
                              )
                            }
                            className={`text-black_900 text-sm hover:text-red_900 flex flex-row items-center justify-between w-full gap-4 whitespace-nowrap ${active.startsWith(`/${department.slug}`) ?'activeLInk':''}`}
                          >
                            {department.name}
                            {department?.subCategory?.length > 0 && <Img
                              className="h-5 w-5"
                              src="images/img_arrowright_red.svg"
                              alt="send"
                            />}
                          </Text>
                          {department?.subCategory?.length > 0 && <div className="department-box-show-hover min-w-[200px] absolute md:relative top-0 py-0 bg-white_A700 h-full border-l md:border-none border-gray_200 h-auto">
                            <div className="flex flex-col gap-0 bg-white_A700">
                              {department?.subCategory?.map(
                                (subCategory, index2) => (
                                  <div
                                    className={`flex flex-row items-center justify-between w-full gap-4 py-2 px-4 sub-category-row ${ active === `/${department.slug}/${subCategory.slug}` ? "active" : ""}`}
                                    key={`subCategory-${index}-${index2}`}
                                    onClick={() =>
                                      navigate(
                                        `/${department.slug}/${subCategory.slug}`
                                      )
                                    }
                                  >
                                    <Text className={` text-sm hover:text-red_900 overflow-hidden whitespace-nowrap text-ellipsis  ${active === `/${department.slug}/${subCategory.slug}` ?'activeLInk':'text-black_900'}`}>
                                      {subCategory.name}
                                    </Text>
                                  </div>
                                )
                              )}
                            </div>
                          </div>}
                        </div>
                      ))}
                  </div>
                </div>
              </div>
              <div
                className={`flex flex-row gap-2 items-center justify-start cursor-pointer h-full md:h-auto a-link ${ active === "/purchase-plan" ? "active" : ""}`}
                onClick={() => {navigate("/purchase-plan");
                const chatDiv = document.querySelector('.your-div-selector'); 
                chatDiv.classList.remove("active");
                setActive(pathname)
            }}
              >
                <Text className="text-black_900 text-sm hover:text-red_900 flex items-center flex-row gap-2 ">
                  <Img
                    className="h-6"
                    src="images/img_bookmark-red.svg"
                    alt="close"
                  />
                  Subscription
                </Text>
              </div>
              <div
                className="flex flex-row gap-2 items-center justify-start cursor-pointer h-full md:h-auto a-link your-div-selector relative"
                onClick={toggleChatBox}
              >
                <Text className="text-black_900 text-sm hover:text-red_900 flex items-center flex-row gap-2">
                  {" "}
                  <Img
                    className="h-6 w-6"
                    src="images/img_laptop_red.svg"
                    alt="laptop"
                  />
                  Message
                </Text>
                {isreceiveCount>0 ? <><div className="absolute inline-flex items-center justify-center w-5 h-5 text-[10px] font-bold text-white bg-red_700 border-2 border-white rounded-full -top-2 left-[14px] dark:border-gray-900">{isreceiveCount}</div></> : null}
                
              </div>
            </div>
            <Line className="bg-gray-200 h-[38px] ml-8 md:ml-4 my-4 w-px" />
            <Button
              id="profile-button"
              className="common-pointer flex flex-row items-center justify-center ml-[32px] md:ml-4 w-auto min-h-[70px] "
              aria-controls={open ? "profile-menu-student" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
              variant={"Transparent"}
            >
              <Img
                className="h-10 md:h-auto rounded-[50%] w-10"
                src={userData?.profile_image}
                alt="unsplashzhvm3xi"
                type="profile"
              />
              <Text
                className="sm:hidden ml-3 text-black_900 text-sm hover:text-red_900"
                size="txtProximaNovaRegular14"
              >
                {userData?.firstName}
              </Text>
              <Img
                className="sm:hidden h-4 ml-4 w-4"
                src="images/img_arrowdown_black_900_16x16.svg"
                alt="arrowdown"
              />
            </Button>
            <Menu
              id="profile-menu-student"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "profile-button",
              }}
            >
              <MenuItem onClick={handleProfile} className="gap-2">
                <Img
                  // src={userData?.profile_image}
                  className="h-5 w-5 raunded-[50%]"
                  alt="user"
                  type="profile"
                />
                <Text
                  className="font-proximanova text-black_900"
                  variant="body2"
                >
                  {/* {userData?.firstName} {userData?.lastName} */}
                  Profile
                </Text>
              </MenuItem>

              <MenuItem className="gap-2" onClick={()=>{
                closeChats(false)
                navigate("/dashboard-student")
              }}>
                <Img
                  src="images/img_dashboard.svg"
                  className="h-5 w-5 raunded-[50%]"
                  alt="user"
                />
                <Text
                  className="font-proximanova text-black_900"
                  variant="body2"
                >
                  {t("Dashboard")}
                </Text>
              </MenuItem>

              {userAge < 18 ? (
                <MenuItem onClick={handleParentPassword} className="gap-2">
                  <Img
                    src="images/img_laptop.svg"
                    className="h-5 w-5 raunded-[50%]"
                    alt="user"
                  />
                  <Text
                    className="font-proximanova text-black_900"
                    variant="body2"
                  >
                    {t("parentPortal")}
                  </Text>
                </MenuItem>
              ) : null}

              <MenuItem
                className="gap-2"
                onClick={() => navigate("/manage-sessions")}
              >
                <Img
                  src="images/my-session-icon.svg"
                  className="h-5 w-5 raunded-[50%]"
                  alt="user"
                />
                <Text
                  className="font-proximanova text-black_900"
                  variant="body2"
                >
                  {t("mySessions")}
                </Text>
              </MenuItem>
              <MenuItem className="gap-2" onClick={handleMySubscription}>
                <Img
                  src="images/img_bookmark.svg"
                  className="h-5 w-5 raunded-[50%]"
                  alt="user"
                />
                <Text
                  className="font-proximanova text-black_900"
                  variant="body2"
                >
                  {t("mySubscription")}
                </Text>
              </MenuItem>
              <MenuItem
                className="gap-2"
                onClick={() => navigate("/my-credit")}
              >
                <Img
                  src="images/img_credit-card.svg"
                  className="h-5 w-5 raunded-[50%]"
                  alt="user"
                />
                <Text
                  className="font-proximanova text-black_900"
                  variant="body2"
                >
                  {t("myCredits")}
                </Text>
              </MenuItem>
              <MenuItem
                className="gap-2"
                onClick={() => navigate("/student-my-tips")}
              >
                <Img
                  src="images/icon-redeem.svg"
                  className="h-5 w-5 raunded-[50%]"
                  alt="user"
                />
                <Text
                  className="font-proximanova text-black_900"
                  variant="body2"
                >
                  {t("myTips")}
                </Text>
              </MenuItem>
              <MenuItem onClick={handleChangePassword} className="gap-2">
                <Img
                  src="images/img_lock_black_900.svg"
                  className="h-5 w-5"
                  alt="lock"
                />
                <Text
                  className="font-proximanova text-black_900"
                  variant="body2"
                >
                  {t("changePassword")}
                </Text>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  closeChats(false)
                  setAnchorEl(null);
                  setLogoutModalOpen(true);
                }}
                className="gap-2"
              >
                <Img
                  src="images/img_logout.svg"
                  className="h-5 w-5"
                  alt="lock"
                />
                <Text
                  className="font-proximanova text-black_900"
                  variant="body2"
                >
                  Logout
                </Text>
              </MenuItem>
            </Menu>
          </div>
        </div>
      </header>
      {LogoutModalOpen === true ? (
        <LogoutModal
          closepopup={setLogoutModalOpen}
          logoutSuccess={() => {
            setLogoutModalOpen(false);
            dispatch(changeToken());
            dispatch(
              changeSnackbar({
                isOpen: true,
                message: "Logout Successfully",
                type: "success",
              })
            );
            navigate("/");
          }}
        ></LogoutModal>
      ) : null}
      {ChangePasswordOpen === true ? (
        <ChangePasswordModal
          closepopup={setChangePasswordOpen}
        ></ChangePasswordModal>
      ) : null}
      {ParentPasswordOpen === true ? (
        <ParentPasswordModal
          closepopup={setParentPasswordOpen}
        ></ParentPasswordModal>
      ) : null}
      {ParentPortalOpen === true ? (
        <ParentPortalModal closepopup={setParentPortalOpen}></ParentPortalModal>
      ) : null}
    </>
  );
};
StudentHeader.defaultProps = {};
export default StudentHeader;
