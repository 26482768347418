import React from "react";
import { Button, Img, Text, PasswordInput } from "components";
import { useTranslation } from "react-i18next";
import API from "tutorEnum";
import globalRequest from "global-modules/globalRequest";
import { useDispatch } from "react-redux";
import { changeSnackbar } from "redux/reducers/snackbar";
const ChangePasswordModal = ({ closepopup }) => {
  const { t } = useTranslation();
  const [oldPassword, setOldPassword] = React.useState("");
  const [newPassword, setNewPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");
  const dispatch = useDispatch();
  // error
  const [oldPasswordError, setOldPasswordError] = React.useState(false);
  const [oldPasswordErrorText, setOldPasswordErrorText] = React.useState("");
  const [newPasswordError, setNewPasswordError] = React.useState(false);
  const [newPasswordErrorText, setNewPasswordErrorText] = React.useState("");
  const [confirmPasswordError, setConfirmPasswordError] = React.useState(false);
  const [confirmPasswordErrorText, setConfirmPasswordErrorText] =
    React.useState("");
  const handleError = () => {
    let error = false;
    if (oldPassword === "") {
      setOldPasswordErrorText("Please enter old password");
      setOldPasswordError(true);
      error = true;
    }
    if (newPassword === "") {
      setNewPasswordErrorText("Please enter new password");
      setNewPasswordError(true);
      error = true;
    }
    if (confirmPassword === "") {
      setConfirmPasswordErrorText("Please enter confirm password");
      setConfirmPasswordError(true);
      error = true;
    }
    if (oldPassword)
      if (oldPassword.length < 8) {
        setOldPasswordErrorText("Password must be at least 8 characters");
        setOldPasswordError(true);
        error = true;
      }
    if (newPassword)
      if (newPassword.length < 8) {
        setNewPasswordErrorText("Password must be at least 8 characters");
        setNewPasswordError(true);
        error = true;
      }
    if (confirmPassword)
      if (confirmPassword.length < 8) {
        setConfirmPasswordErrorText("Password must be at least 8 characters");
        setConfirmPasswordError(true);
        error = true;
      }
    if (newPassword !== confirmPassword) {
      setConfirmPasswordErrorText("Password does not match");
      setConfirmPasswordError(true);
      error = true;
    }
    return error;
  };
  const handleSave = async () => {
    if (handleError()) {
      return;
    }
    let url = API.TUTOR_AUTH.Tutor_Change_Password;
    if (newPassword === confirmPassword) {
      let data = {
        newPassword: newPassword,
        oldPassword: oldPassword,
      };
      let responce = await globalRequest("put", url, data, {}, true, true);
      if (responce.status === "SUCCESS") {
        dispatch(
          changeSnackbar({
            isOpen: true,
            message: responce.message,
            state: "success",
          })
        );
        closepopup(false);
      } else {
        dispatch(
          changeSnackbar({
            isOpen: true,
            message: responce.message,
            state: "error",
          })
        );
      }
    }
  };
  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none sm:px-4 w-fit h-fit m-auto">
        <div className="relative w-auto my-auto mx-auto max-w-460 min-w-[460px] sm:min-w-full">
          {/*content*/}
          <div className="border-0 shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            {/*header*/}
            <div className="p-6 relative">
              <Text
                className="font-proximanova text-black_900 mb-1"
                variant="h3"
                as="h3"
              >
                {t("changePassword")}
              </Text>
              <Text
                className="font-proximanova text-black_900 mb-4"
                variant="body1"
                as="p"
              >
                {t(
                  "mustIncludeAtLeast8Characters_&_shouldNotHaveAnyPartOfYourName"
                )}
              </Text>
              <button
                className="w-6 h-6 ml-auto bg-transparent border-0 text-black opacity-75 float-right text-3xl leading-none font-semibold outline-none focus:outline-none absolute right-0 top-0 mt-3 mr-3"
                onClick={() => closepopup(false)}
              >
                <Img src="images/modal_close.svg" alt="close" />
              </button>
            </div>
            {/*body*/}
            <div className="relative px-6 pb-6 flex-auto">
              <PasswordInput
                label={t("oldPassword")}
                error={oldPasswordError}
                errorText={oldPasswordErrorText}
                required={true}
                onChange={(e) => {
                  setOldPassword(e.target.value);
                  setOldPasswordError(false);
                  setOldPasswordErrorText("");
                }}
              />
              <PasswordInput
                label={t("newPassword")}
                error={newPasswordError}
                errorText={newPasswordErrorText}
                required={true}
                onChange={(e) => {
                  setNewPassword(e.target.value);
                  setNewPasswordError(false);
                  setNewPasswordErrorText("");
                }}
              />
              <PasswordInput
                label={t("confirmPassword")}
                error={confirmPasswordError}
                errorText={confirmPasswordErrorText}
                required={true}
                onChange={(e) => {
                  setConfirmPassword(e.target.value);
                  setConfirmPasswordError(false);
                  setConfirmPasswordErrorText("");
                }}
              />
              <Button
                className="mt-4 ease-linear transition-all duration-150 w-full"
                variant={"FillRed900"}
                size="md"
                onClick={() => handleSave()}
              >
                {t("save")}
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div
        className="opacity-25 fixed inset-0 z-40 bg-black"
        onClick={() => closepopup(false)}
      ></div>
    </>
  );
};
export { ChangePasswordModal };