import React from "react";

const ThreeSteps = () => {
  const stepsData = [
    {
      title: "Share your goals",
      text: "To help us discover the best tutors for your needs, please let us know what kind of tutoring you require and when.",
      image: "https://i.postimg.cc/90LQD66f/share-your-goals-2-removebg-preview.png",
    },
    {
      title: "Find the perfect fit",
      text: "Find the tutor who most appeals to you by looking through our tutors, contacting them, and scheduling as many free meetings as necessary.",
      image: "https://i.postimg.cc/J4q8nh4R/find-the-perfect-fit-1.png",
    },
    {
      title: "Let the learning begin",
      text: "When you've decided that Battersea House is the best fit, it's time to schedule your sessions and begin learning.",
      image: "https://i.postimg.cc/mgpGY6Hq/start-learning-1.png",
    },
  ];

  return (
    <div className="bg-[#FDF8F6] py-20">
      <div className="max-w-7xl mx-auto px-4">
        <h2 className="text-center mb-16 text-3xl font-trajanpro text-red-500">
          Start learning in 3 steps
        </h2>
        <div className="grid grid-cols-3 md:grid-cols-3 gap-8">
          {stepsData.map((step, index) => (
            <div key={index} className="flex flex-col items-center text-center">
              <div className="mb-8">
                <img
                  src={step.image}
                  alt={step.title}
                  className="w-full h-auto"
                />
              </div>
              <h3 className="text-2xl font-semibold mb-4 font-trajanpro text-red-500">
                {step.title}
              </h3>
              <p className="text-gray-700">{step.text}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ThreeSteps;
