import React from "react";
import { Button, Img, Text } from "components";
import { useNavigate } from "react-router-dom";

const CTA = () => {

  const navigate = useNavigate();

  return (
    <>
      <div className="hm-sec5 relative mt-[50px] md:mt-[137px] sm:mt-16">
        <div className="max-w-[1140px] pl-[15px] pr-[15px] md:w-full mx-auto">
          <div
            className="h-[260px] sm:h-[242px] relative bg-right-top"
            style={{
              backgroundImage: "url('images/becom-tutor-bg-img.webp')",
              backgroundSize: "cover",
            }}
          >
            <div className="max-w-[680px] smx:max-w-[500px] w-full px-[50px] py-[40px] sm:p-[30px]">
              <Text
                className="font-trajanpro font-bold text-[40px] text-white_A700 mb-4 leading-normal"
                variant={"h6"} as="h6"
              > Get Started </Text>
              <Text className="mb-[30px] block text-white_A700 font-normal text-sm mx-auto">
                Unlock your potential as an online student and expand your knowledge from the comfort of your own home. Join the digital education revolution today!
              </Text>
              <Button
                className="cursor-pointer font-semibold min-w-[180px] text-base text-center bg-white_A700 text-red_900"
                size="md" onClick={() => navigate("/studentsignup")}
              > Start Learning Today </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default CTA;
