import React, { useState } from "react";

const FAQ = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const faqData = [
    {
      question: "What subjects do you offer for GCSE tutoring?",
      answer:
        "We offer tutoring for Maths, English, Science (Biology, Chemistry, Physics), Languages (French, Spanish, etc.), and more. Our lessons are tailored to individual needs.",
      category: "Subjects",
    },
    {
      question: "Are your tutors familiar with all major exam boards?",
      answer:
        "Yes, our tutors are experienced with AQA, Edexcel, OCR, and other major exam boards. They align lessons to meet specific syllabus requirements.",
      category: "Qualifications",
    },
    {
      question: "How do I schedule a lesson?",
      answer:
        "You can schedule lessons directly through our platform. Simply log in, select your subject and tutor, and pick a time slot that works for you.",
      category: "Booking",
    },
  ];

  return (
    <section className="bg-gradient-to-b from-white to-gray-50 py-20">
      <style jsx>{`
        .faq-content {
          max-height: 0;
          overflow: hidden;
          transition: max-height 0.3s ease-out;
        }

        .faq-content.active {
          max-height: 200px;
        }

        .chevron {
          transition: transform 0.3s ease;
        }

        .chevron.active {
          transform: rotate(180deg);
        }
      `}</style>

      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-16">
          <h2 className="text-4xl font-bold text-gray-900 mb-4">
            Frequently Asked Questions
          </h2>
          <p className="text-lg text-gray-600">
            Find answers to common questions about our tutoring services
          </p>
        </div>

        <div className="max-w-3xl mx-auto space-y-4">
          {faqData.map((item, index) => (
            <div
              key={index}
              className="bg-white rounded-lg shadow-sm border border-gray-100"
            >
              <button
                className="w-full text-left px-6 py-4 flex justify-between items-center hover:bg-gray-50 transition-colors duration-200"
                onClick={() =>
                  setActiveIndex(activeIndex === index ? null : index)
                }
              >
                <div className="flex items-center space-x-4">
                  <span className="text-red-500 text-sm font-semibold">
                    {item.category}
                  </span>
                  <h3 className="text-gray-900 font-medium">{item.question}</h3>
                </div>
                <svg
                  className={`w-5 h-5 text-gray-400 chevron ${
                    activeIndex === index ? "active" : ""
                  }`}
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M19 9l-7 7-7-7"
                  />
                </svg>
              </button>
              <div
                className={`faq-content ${
                  activeIndex === index ? "active" : ""
                }`}
              >
                <div className="px-6 pb-4 text-gray-600">{item.answer}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default FAQ;