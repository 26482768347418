import React, { useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { Img, Line, Text, Button } from "components";
import { Menu, MenuItem } from "@mui/material";
import { ChangePasswordModal } from "modals/ChangePasswordModal";
import { LogoutModal } from "modals/LogoutModal";
import { PayoutDetailApprove } from "modals/PayoutDetailApprove";
import { useTranslation } from "react-i18next";
import { changeSnackbar } from "redux/reducers/snackbar";
import { useDispatch, useSelector } from "react-redux";
import { currentUserData, changeToken } from "redux/reducers/token";
import { changeChatbox, currentChatbox,changeChatObject } from "redux/reducers/chatbox";
import globalRequest from "global-modules/globalRequest";
import API from "tutorEnum";
import  socket  from "../../socket";

const categoryColors = {
  premium: "text-teal_500",
  executive: "text-purple_400",
  standard: "text-blue_700",
};

const HeaderAfterLogin = (props) => {
  const { t } = useTranslation();
  const { slug } = useParams();
  const navigate = useNavigate();
  const currentChat = useSelector(currentChatbox);
  const [ChangePasswordOpen, setChangePasswordOpen] = React.useState(null);
  const [LogoutModalOpen, setLogoutModalOpen] = React.useState(null);
  const [payoutDetailApproveOpen, setPayoutDetailApproveOpen] = React.useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [departmentAnchorEl, setDepartmentAnchorEl] = React.useState(null);
  const [isHovered, setIsHovered] = React.useState(false);
  const [departments, setDepartments] = React.useState([]);
  const [isOpenDepartment, setIsOpenDepartment] = React.useState(false);
  const [isreceiveCount, setReceiveCount] = React.useState(0);
  const userData = useSelector(currentUserData);
  const dispatch = useDispatch();
  const open = Boolean(anchorEl);
  const [active, setActive] = React.useState(null);
  const openDepartment = Boolean(departmentAnchorEl);
  const isMaxDepartment = !isOpenDepartment ? "md:hidden" : "md-show";
  const [departmentsUrls, setDepartmentsUrls] = useState([]);
  const { pathname } = useLocation();

  const handleProfile = () => {
    navigate("/tutor-profile");
    setAnchorEl(null);
  };
  const handleChangePassword = () => {
    setChangePasswordOpen(true);
    setAnchorEl(null);
  };
  const closeChats = async () => {
    dispatch(
      changeChatObject({
        tutorId: 0,
        isSingle: false,
        tutor: null,
      })
    );
    dispatch(changeChatbox(false));
  };

  const handleLogout = () => {
    closeChats(false)
    setLogoutModalOpen(true);
    setAnchorEl(null);
  };

  const toggleChatBox = () => {
    dispatch(changeChatbox(!currentChat));
  };

  const getDepartment = async () => {

    const response = await globalRequest(
      "get",
      API.POST.GET_ALL_DEPARTMENT,
      {},
      {},
      true,
      true
    );

    setDepartments(response.data);

    let urls = [];

    for(const cat of response.data){
      urls.push(`/${cat.slug}`);
      for(const subCat of cat.subCategory){
        urls.push(`/${cat.slug}/${subCat.slug}`);
      }
    }

    setDepartmentsUrls(urls);

  };

  const handleToggleDepartment = () => {
    setIsOpenDepartment(!isOpenDepartment);
    // navigate("/student-department-wall");
  };
  const handleMouseEnter = () => {
    setIsHovered(true);
  };
  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  React.useEffect(() => {
    getDepartment();
    socket?.on('recieveCount', (data) => {
      setReceiveCount(data.count);
    });
  }, []);

  React.useEffect(() => {
    setActive(pathname);
  }, [pathname]);

  React.useEffect(() => {
    if (LogoutModalOpen === true || ChangePasswordOpen === true) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }
    return () => {
      document.body.classList.remove("overflow-hidden");
    };
  }, [LogoutModalOpen, ChangePasswordOpen]);
  React.useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === "Escape") {
        setLogoutModalOpen(false);
        setChangePasswordOpen(false);
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  React.useEffect(() => {
    if (isHovered) {
      setDepartments(
        departments.map((department) => {
          if (
            department.subCategory.find(
              (subCategory) => subCategory.slug === slug
            )
          ) {
            return {
              ...department,
              subCategory: department.subCategory.map((subCategory) => {
                if (subCategory.slug === slug) {
                  return {
                    ...subCategory,
                    isActive: true,
                  };
                } else {
                  return {
                    ...subCategory,
                    isActive: false,
                  };
                }
              }),
              isActive: true,
            };
          } else {
            return {
              ...department,
              subCategory: department.subCategory.map((subCategory) => {
                return {
                  ...subCategory,
                  isActive: false,
                };
              }),
              isActive: false,
            };
          }
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slug, isHovered]);

  return (
    <>
      <header className="bg-white_A700 flex md:flex-col flex-row md:gap-5 items-center justify-center w-full fixed top-0 z-[39]">
        <div className="w-full px-[30px] md:px-[15px] flex justify-between">
          <div className="header-after-left">
            <Img
              onClick={() => navigate("/")}
              className="my-2.5 object-cover w-[230px] h-[50px] md:hidden"
              src="images/img_batterseahouse1.png"
              alt="batterseahouse"
            />
            <Img
              onClick={() => navigate("/")}
              className=" my-2.5 object-cover w-[48px] h-[48px] hidden md:block"
              src="images/img_batterseahouse-logo.png"
              alt="batterseahouse"
            />
          </div>
          <div className="header-after-right flex flex-row items-center">
            <div
              className={`${
                isHovered
                  ? "flex flex-row md:flex-col gap-2 items-center md:items-start justify-start cursor-pointer h-full md:h-auto department-parent show sm:fixed sm:right-[165px] sm:top-[22px]"
                  : "flex flex-row md:flex-col gap-2 items-center md:items-start justify-start cursor-pointer h-full md:h-auto department-parent hide sm:fixed sm:right-[165px] sm:top-[22px]"
              }`}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              {/* active-link */}
              <Text
                className={`text-black_900 text-sm hover:text-red_900 flex items-center flex-row gap-2 md:w-full md:flex md:justify-end  ${departmentsUrls.includes(active) ? "text-red_900" : ""}`}
                onClick={handleToggleDepartment}
              >
                {/* onClick={() => navigate("/student-department-wall")} */}
                <Img
                  className="h-6"
                  src="images/icon-department-red.svg"
                  alt="mail"
                />
                {t("department")}
              </Text>

              <div
                className={`department-box w-auto bg-white_A700 py-0 absolute md:relative top-[70px] md:top-auto z-50 show ${isMaxDepartment}`}
              >
                <div className="flex flex-col gap-0 border-r md:border-none border-gray_200">
                  {departments?.length > 0 &&
                    departments?.map((department, index) => (
                      <div
                        className="flex flex-row md:flex-col items-center justify-between w-full gap-4 department-row py-2 px-4 relative"
                        key={`department-${index}`}
                      >
                        <Text
                          className={`text-black_900 text-sm hover:text-red_900 flex flex-row items-center justify-between w-full gap-4 whitespace-nowrap 
                          ${active.startsWith(`/${department.slug}`) ? "text-red_900" : ""}`}
                          onClick={() =>
                            navigate(
                              `/${department.slug}`
                            )
                          }
                        >
                          {department.name}
                          { department.subCategory?.length > 0 && <Img
                            className="h-5 w-5"
                            src="images/img_arrowright_red.svg"
                            alt="send"
                          />}
                        </Text>
                        { department.subCategory?.length > 0 && <div className="department-box-show-hover min-w-[200px] md:min-w-[auto] absolute md:relative top-0 py-0 bg-white_A700 h-full border-l md:border-none border-gray_200 h-auto">
                          <div className="flex flex-col gap-0 bg-white_A700">
                            {department.subCategory?.map(
                              (subCategory, index2) => (
                                <div
                                  className="flex flex-row items-center justify-between w-full gap-4 py-2 px-4"
                                  key={`subCategory-${index}-${index2}`}
                                  onClick={() =>
                                    navigate(
                                      `/${department.slug}/${subCategory.slug}`
                                    )
                                  }
                                >
                                  <Text
                                    className={` text-sm hover:text-red_900 overflow-hidden whitespace-nowrap text-ellipsis 
                                    ${active.includes(`/${department.slug}/${subCategory.slug}`) ? "text-red_900" : ""}`}
                                  >
                                    {subCategory.name}
                                  </Text>
                                </div>
                              )
                            )}
                          </div>
                        </div>}
                      </div>
                    ))}
                </div>
              </div>
            </div>
            <div
              className="flex flex-row gap-2 items-center justify-center my-[23px] w-auto cursor-pointer ml-6 sm:ml-2"
              onClick={() => {
                if(userData?.stripeAcStatus === 0 || userData?.stripeAcStatus == null){
                  setPayoutDetailApproveOpen(true);
                  sessionStorage.removeItem("SessionId")
                }else{
                  setPayoutDetailApproveOpen(false);
                  sessionStorage.removeItem("SessionId") ;
                  navigate("/tutor-create-lesson")
                }
              }}
            >
              {/* sessionStorage.removeItem("SessionId");
                navigate("/tutor-create-lesson"); */}
              <Img src="images/img_plus.svg" className="h-6 w-6" alt="plus" />
              <Text
                className="font-normal font-proximanova text-black_900 sm:hidden"
                variant="body2"
              >
                {t("createLesson")}
              </Text>
            </div>
            <div className="relative ml-6 sm:ml-3 my-[23px]">
            <Img
              src="images/img_laptop.svg"
              className="h-6 w-6 cursor-pointer"
              alt="laptop"
              onClick={toggleChatBox}
            /> {isreceiveCount>0 ? <><div className="absolute inline-flex items-center justify-center w-5 h-5 text-[10px] font-bold text-white bg-red_700 border-2 border-white rounded-full -top-2 left-[14px] dark:border-gray-900">{isreceiveCount}</div></> : null}
            </div>
            <Line className="bg-gray_200 h-[38px] mx-4 sm:mx-2 w-px" />
            <Button
              id="profile-button"
              className="flex items-center justify-start min-h-[70px]"
              aria-controls={open ? "profile-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={(event) => setAnchorEl(event.currentTarget)}
              variant={"Transparent"}
            >
              <Img
                src={userData?.profile_image}
                className=" md:h-auto rounded-[50%] w-10 h-10"
                alt="unsplashzhvm3xi"
                type="profile"
              />
              <div className="flex flex-col items-start justify-start smx:hidden mx-3">
                <Text
                  className="font-normal font-proximanova text-black_900 capitalize mb-[2px]"
                  variant="body2"
                >
                  {userData?.firstName} {userData?.lastName}
                </Text>
                <Text
                  className={`capitalize ${categoryColors[userData?.category]}`}
                  variant="body3"
                >
                  {userData?.category}
                </Text>
              </div>
              <Img
                src="images/img_arrowdown_gray_600.svg"
                className="h-4 w-4"
                alt="arrowdown"
              />
            </Button>
            <Menu
              id="profile-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={() => setAnchorEl(null)}
              MenuListProps={{ "aria-labelledby": "profile-button" }}
            >
              <MenuItem onClick={handleProfile}>
                <div className="flex flex-row gap-2 h-max inset-[0] items-center justify-center">
                  <Img
                    src="images/img_user_black_900_20x20.svg"
                    className="h-5 w-5"
                    alt="user"
                  />
                  <div className="relative">
                    <Text
                      className="font-proximanova text-black_900"
                      variant="body2"
                      as="p"
                    >
                      {userData?.firstName} {userData?.lastName}
                    </Text>
                    <Text
                      className="font-proximanova text-black_900"
                      variant="body3"
                    >
                      {userData?.email}
                    </Text>
                  </div>
                </div>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  sessionStorage.removeItem("SessionId");
                  navigate("/tutor-create-lesson");
                }}
                className="create-lesson-md gap-2"
              >
                <Img src="images/img_plus.svg" className="h-5 w-5" alt="lock" />
                <Text
                  className="font-proximanova text-black_900"
                  variant="body2"
                >
                  {t("createLesson")}
                </Text>
              </MenuItem>
              <MenuItem onClick={handleChangePassword} className="gap-2">
                <Img
                  src="images/img_lock_black_900.svg"
                  className="h-5 w-5"
                  alt="lock"
                />
                <Text
                  className="font-proximanova text-black_900"
                  variant="body2"
                >
                  {t("changePassword")}
                </Text>
              </MenuItem>
              {/* <MenuItem
              onClick={() => navigate("/payout-detail")}
              className="gap-2"
            >
              <Img
                src="images/img_upload_gray_900.svg"
                className="h-5 w-5"
                alt="upload"
              />
              <Text className="font-proximanova text-black_900" variant="body2">
              {t('payoutBankDetail')}
              </Text>
            </MenuItem> */}
              <MenuItem onClick={handleLogout} className="gap-2">
                <Img
                  src="images/img_logout.svg"
                  className="h-5 w-5"
                  alt="lock"
                />
                <Text
                  className="font-proximanova text-black_900"
                  variant="body2"
                >
                  {t("logout")}
                </Text>
              </MenuItem>
            </Menu>
          </div>
        </div>
      </header>
      {payoutDetailApproveOpen === true ? (
        <PayoutDetailApprove
          closepopup={setPayoutDetailApproveOpen}
        ></PayoutDetailApprove>
      ) : null}
      {LogoutModalOpen === true ? (
        <LogoutModal
          closepopup={setLogoutModalOpen}
          logoutSuccess={() => {
            setLogoutModalOpen(false);
            dispatch(changeToken());
            dispatch(
              changeSnackbar({
                isOpen: true,
                message: "Logout Successfully",
                type: "success",
              })
            );
            navigate("/");
          }}
        ></LogoutModal>
      ) : null}
      {ChangePasswordOpen === true ? (
        <ChangePasswordModal
          closepopup={setChangePasswordOpen}
        ></ChangePasswordModal>
      ) : null}
    </>
  );
};
HeaderAfterLogin.defaultProps = {};
export { HeaderAfterLogin };
