import React from "react";
import { Text } from "components";

const BorderedContentBlock = ({title, content}) => {
  return (
    <div className="max-w-[1140px] pl-[15px] pr-[15px] md:w-full mx-auto">
      <div className="text-center">

        {/* Curriculum Details Section */}
        <div className="mt-8 bg-transparent p-6 border border-gray-200 rounded-lg">
          <Text
            as="h3"
            variant="h3"
            className="text-xl font-bold text-black font-trajanpro mb-4"
          >
            {title}
          </Text>
          <Text
            variant="p"
            className="mt-4 text-black leading-relaxed text-base"
            // Convert `\n` to <br /> before rendering
            dangerouslySetInnerHTML={{
              __html: content.replace(/\n/g, "<br />")
            }}
          >
          </Text>
        </div>        

      </div>
    </div>
  );
};

export default BorderedContentBlock;