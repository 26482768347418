import React from "react";
import { useNavigate } from "react-router-dom";

const tutors2 = [
  {
    id: 1,
    name: "John Smith",
    photo: "https://bird.co.uk/wp-content/uploads/2024/05/georgec-photo.jpg",
    bio: "Experienced Maths tutor specializing in algebra, geometry, and calculus. Master's degree in Mathematics with 10+ years of tutoring experience.",
    expertise: ["Maths", "Physics", "Statistics"],
  },
  {
    id: 2,
    name: "Jane Doe",
    photo: "https://bird.co.uk/wp-content/uploads/2024/05/georgec-photo.jpg",
    bio: "English tutor passionate about literature and essay writing. Degree in English Literature, helping students achieve top grades.",
    expertise: ["English", "Literature", "Essay Writing"],
  },
  {
    id: 3,
    name: "Carlos Garcia",
    photo: "https://bird.co.uk/wp-content/uploads/2024/05/georgec-photo.jpg",
    bio: "Science tutor with expertise in Biology and Chemistry. PhD in Biochemistry and a knack for simplifying complex topics.",
    expertise: ["Biology", "Chemistry", "General Science"],
  },
  {
    id: 4,
    name: "Emily Taylor",
    photo: "https://bird.co.uk/wp-content/uploads/2024/05/georgec-photo.jpg",
    bio: "Passionate History tutor specializing in modern and ancient history. BA in History and a certified educator with 8 years of experience.",
    expertise: ["History", "Geography", "Politics"],
  },
  {
    id: 5,
    name: "Michael Brown",
    photo: "https://bird.co.uk/wp-content/uploads/2024/05/georgec-photo.jpg",
    bio: "Expert in Business Studies and Economics. MBA graduate with extensive teaching experience in business case studies and financial analysis.",
    expertise: ["Business Studies", "Economics", "Finance"],
  },
  {
    id: 6,
    name: "Sophia Wilson",
    photo: "https://bird.co.uk/wp-content/uploads/2024/05/georgec-photo.jpg",
    bio: "Languages tutor fluent in Spanish and French. Years of experience helping students develop conversational and written language skills.",
    expertise: ["Spanish", "French", "Language Arts"],
  },
];

const TutorGrid = ({tutors}) => {
  const navigate = useNavigate();
  
  return (
    <div className="mx-auto px-8 py-12">
      <h2 className="text-3xl font-bold text-center text-red-500 mb-8 font-trajanpro">
        Meet Our Expert Tutors
      </h2>
      <div
        className="grid gap-8"
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(4, 1fr)",
          gridTemplateRows: "repeat(2, auto)",
        }}
      >
        {tutors.map((tutor) => (
          <div key={tutor.id} className="card bg-white w-96 shadow-xl rounded-none">
            <figure>
              <img
                src={tutor.profile_image}
                alt={`${tutor.firstName} ${tutor.lastName}'s profile`}
                className="w-full h-64 object-cover rounded-none"
                style={{cursor:'pointer'}}
                onClick={() => navigate(`/tutor-detail/${tutor.slug}`)}
              />
            </figure>
            <div className="card-body">
              <h2 
                className="card-title text-black"
                style={{cursor:'pointer'}}
                onClick={() => navigate(`/tutor-detail/${tutor.slug}`)}
              >
                {`${tutor.firstName} ${tutor.lastName}`}
                <div className="badge badge-secondary">Tutor</div>
              </h2>
              <p className="text-gray-700">{tutor.description}</p>
              <div className="card-actions justify-start mt-4">
                {tutor.userCategorySubjects.map((subject) => (
                  <div
                    key={subject.subId}
                    className="badge badge-outline text-red-500 border-red-500"
                  >
                    {subject._subId.name}
                  </div>
                ))}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TutorGrid;
