import React, { useEffect } from "react";
import MainHeader from "components/MainHeader"; // Main header for navigation and branding
import StaticFooter from "components/StaticFooter"; // Footer with site links and contact details
import BecomeTutor from "pages/Static/Homepage/BecomeTutor";
import Testimonial from "pages/Static/Homepage/Testimonial";
import { currentUserData } from "redux/reducers/token"; // Redux selector for current user data
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet"; // SEO-related tags for the head section
import SubjectBanner from "./banners/subject";
import FAQ from "./FAQ";
import TutorGrid from "./Tutor-Grid";
import CTA from "./CTA";
import ContentBlock from "./ContentBlock";
import BorderedContentBlock from "./BorderedContentBlock";
import ThreeSteps from "./Three-Steps";
import { useParams } from "react-router-dom";
import globalRequest from "global-modules/globalRequest";
import API from "tutorEnum";

const FinalTemplate = () => {
  const userData = useSelector(currentUserData);

  const { slug, subslug } = useParams();
  let [departmentDetails, setDepartmentDetails] = React.useState({});
  let [topTutors, setTopTutors] = React.useState([]);

  // Set the page title dynamically for SEO
  useEffect(() => {
    if (!departmentDetails.id) {
      getDepartmentPost();
    }
    //document.title = "English Language";
  }, [departmentDetails]);

  const getDepartmentPost = async () => {

    const slugSearch = subslug ? subslug : slug;
    const response = await globalRequest('get', API.POST.GET_DEPARTMENT(slugSearch), {}, {}, true, true);
    departmentDetails = response?.data || {};
    setDepartmentDetails(departmentDetails);
    getTopTutors();
  };

  const getTopTutors = async () => {
    let url = API.POST.GET_TOP_TUTORS;
    url += `?department=${departmentDetails.id}&random=1&limit=9`;
    const response = await globalRequest('get', url, {}, {}, true, true);
    topTutors = response?.data || [];
    setTopTutors(topTutors)
  };

  return (
    <>
      {/* Helmet for SEO meta tags */}

      {departmentDetails.id && <Helmet>
        <title>{departmentDetails.metaTitle}</title>
        <meta
          name="title"
          content={departmentDetails.metaTitle}
          key="title"
        />
        <meta
          name="description"
          content={departmentDetails.metaDescription}
          key="description"
        />
        <meta
          name="keywords"
          content={departmentDetails.metaKeywords}
          key="keywords"
        />
        <link rel="canonical" href={subslug ? `https://batterseahouse.co.uk/${slug}/${subslug}` : `https://batterseahouse.co.uk/${slug}`} />
      </Helmet>}

      {/* Main page container */}
      <div className="bg-white_A700 flex flex-col font-proximanova mx-auto w-full pt-[70px]">
        {/* Navigation Header */}
        <div className="flex flex-col items-center justify-start w-full">
          <MainHeader />
        </div>

        {/* Hero Section with Subject Banner */}
        <SubjectBanner departmentDetails={departmentDetails} />
        {/* TO DO: Enhance banner with a CTA button (e.g., "Book Free Trial"), an engaging headline, and a short description for conversions */}

        {departmentDetails.content && departmentDetails.content_title &&
          <ContentBlock title={departmentDetails.content_title} content={departmentDetails.content} />
        }

        <ThreeSteps />

        {departmentDetails.content_2 && departmentDetails.content_2_title &&
          <ContentBlock title={departmentDetails.content_2_title} content={departmentDetails.content_2} />
        }

        {topTutors.length > 0 && <TutorGrid tutors={topTutors} />}

        {departmentDetails.content_3 && departmentDetails.content_3_title &&
          <BorderedContentBlock title={departmentDetails.content_3_title} content={departmentDetails.content_3} />
        }

        <CTA />

        {departmentDetails.content_4 && departmentDetails.content_4_title &&
          <BorderedContentBlock title={departmentDetails.content_4_title} content={departmentDetails.content_4} />
        }

        {departmentDetails.content_5 && departmentDetails.content_5_title &&
          <BorderedContentBlock title={departmentDetails.content_5_title} content={departmentDetails.content_5} />
        }

        {/* Conditional rendering for tutor recruitment */}
        {/* TO DO: Promote the benefits of becoming a tutor and include a call-to-action button */}
        {/* Testimonial Section */}
        <Testimonial />
        <BecomeTutor />
        {/* TO DO: Add testimonials or success stories for the specific subject (e.g., "English Language"), preferably with before-and-after stats */}
        <FAQ />
      </div>

      {/* Static Footer */}
      <StaticFooter />
      {/* TO DO: Add links to related subjects (e.g., "Maths Tutoring") and trust signals (e.g., payment options, certifications) */}
    </>
  );
};

export default FinalTemplate;
