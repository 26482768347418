import { Img, Text } from "components";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
import { useTranslation } from "react-i18next";
const Testimonial = () => {
  const { t } = useTranslation();
  const settings = {
    dots: true,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    // centerMode: false,
    responsive: [
      {
        breakpoint: 990,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 550,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  return (
    <>
      <div className="hm-sec6 relative pt-[100px] md:pt-16 pb-[85px] md:pb-16">
        <div className="max-w-[1140px] pl-[15px] pr-[15px] md:w-full mx-auto">
          <div className="text-center mb-0">
            <Text
              className="font-trajanpro font-bold text-[32px] text-black_900 text-center"
              variant={"h6"}
              as="h6"
            >
              Customer Testimonials
            </Text>
            <Text
              className="mt-3 block text-black_900 font-normal smx:text-base"
              variant={"h5"}
            >
              Read what our students and parents say about our tutors.
            </Text>
          </div>
          <div className="hmTestimonialSlider">
            <Slider {...settings}>
              <div className="bg-gray_50_01 shadow-bs hover:shadow-hlg transition ease-in-out relative min-h-[300px] relative">
                <div className="px-[30px] pt-[30px] pb-[50px]">
                  <Text className="block text-black_900 font-normal text-sm mx-auto">
                    David is a highly skilled online tutor of computer science.
                    He is patient, thorough, and has a knack for breaking down
                    complex topics into manageable pieces. He provides clear
                    explanations and is always willing to go the extra mile to
                    help his students succeed.{" "}
                  </Text>
                  <div className="flex items-center mt-[30px]">
                    
                    <div>
                      <Text className="block text-black_900 font-semibold text-sm mx-auto">
                      Luna Hawthorne
                      </Text>
                      <Text className="block text-gray-700 font-normal text-xs mx-auto">
                        Parent
                      </Text>
                    </div>
                  </div>
                </div>
                <Img
                  className="h-[101] w-[99] absolute bottom-0 right-0"
                  src="images/testmBgimage1.png"
                  alt="..."
                />
              </div>
              <div className="bg-gray_50_01 shadow-bs hover:shadow-hlg transition ease-in-out relative min-h-[300px] relative">
                <div className="px-[30px] pt-[30px] pb-[50px]">
                  <Text className="block text-black_900 font-normal text-sm mx-auto">
                    Samantha is a friendly and approachable tutor who
                    specialises in English and writing. She is passionate about
                    teaching and has a talent for making complex concepts easy
                    to understand. She provides detailed feedback and encourages
                    her students to ask questions.{" "}
                  </Text>
                  <div className="flex items-center mt-[30px]">
                    
                    <div>
                      <Text className="block text-black_900 font-semibold text-sm mx-auto">
                      Felix Sterling
                      </Text>
                      <Text className="block text-gray-700 font-normal text-xs mx-auto">
                        Parent
                      </Text>
                    </div>
                  </div>
                </div>
                <Img
                  className="h-[101] w-[99] absolute bottom-0 right-0"
                  src="images/testmBgimage2.png"
                  alt="..."
                />
              </div>
              <div className="bg-gray_50_01 shadow-bs hover:shadow-hlg transition ease-in-out relative min-h-[300px] relative">
                <div className="px-[30px] pt-[30px] pb-[50px]">
                  <Text className="block text-black_900 font-normal text-sm mx-auto">
                    John is an experienced online tutor in math and science. He
                    is patient, knowledgeable, and has a great sense of humour.
                    He explains concepts clearly and provides helpful feedback.{" "}
                  </Text>
                  <div className="flex items-center mt-[30px]">
                    
                    <div>
                      <Text className="block text-black_900 font-semibold text-sm mx-auto">
                      Aria Wilde
                      </Text>
                      <Text className="block text-gray-700 font-normal text-xs mx-auto">
                        Parent
                      </Text>
                    </div>
                  </div>
                </div>
                <Img
                  className="h-[101] w-[99] absolute bottom-0 right-0"
                  src="images/testmBgimage3.png"
                  alt="..."
                />
              </div>
              <div className="bg-gray_50_01 shadow-bs hover:shadow-hlg transition ease-in-out relative min-h-[300px] relative">
                <div className="px-[30px] pt-[30px] pb-[50px]">
                  <Text className="block text-black_900 font-normal text-sm mx-auto">
                  David is a highly skilled online tutor of computer science.
                    He is patient, thorough, and has a knack for breaking down
                    complex topics into manageable pieces. He provides clear
                    explanations and is always willing to go the extra mile to
                    help his students succeed.{" "}
                  </Text>
                  <div className="flex items-center mt-[30px]">
                    
                    <div>
                      <Text className="block text-black_900 font-semibold text-sm mx-auto">
                        Kenneth Allen
                      </Text>
                      <Text className="block text-gray-700 font-normal text-xs mx-auto">
                        Parent
                      </Text>
                    </div>
                  </div>
                </div>
                <Img
                  className="h-[101] w-[99] absolute bottom-0 right-0"
                  src="images/testmBgimage1.png"
                  alt="..."
                />
              </div>
             {/*  <div className="bg-gray_50_01 shadow-bs hover:shadow-hlg transition ease-in-out relative min-h-[300px] relative">
                <div className="px-[30px] pt-[30px] pb-[50px]">
                  <Text className="block text-black_900 font-normal text-sm mx-auto">
                  Samantha is a friendly and approachable tutor who
                    specialises in English and writing. She is passionate about
                    teaching and has a talent for making complex concepts easy
                    to understand. She provides detailed feedback and encourages
                    her students to ask questions.{" "}
                  </Text>
                  <div className="flex items-center mt-[30px]">
                    <Img
                      className="h-8 w-8 border rounded-full object-cover"
                      src="images/img_rectangle19_1.webp"
                      alt="..."
                    />
                    <div className="pl-[16px]">
                      <Text className="block text-black_900 font-semibold text-sm mx-auto">
                      Luna Hawthorne
                      </Text>
                      <Text className="block text-gray-700 font-normal text-xs mx-auto">
                        Parent
                      </Text>
                    </div>
                  </div>
                </div>
                <Img
                  className="h-[101] w-[99] absolute bottom-0 right-0"
                  src="images/testmBgimage2.png"
                  alt="..."
                />
              </div>
              <div className="bg-gray_50_01 shadow-bs hover:shadow-hlg transition ease-in-out relative min-h-[300px] relative">
                <div className="px-[30px] pt-[30px] pb-[50px]">
                  <Text className="block text-black_900 font-normal text-sm mx-auto">
                  John is an experienced online tutor in math and science. He
                    is patient, knowledgeable, and has a great sense of humour.
                    He explains concepts clearly and provides helpful feedback.{" "}
                  </Text>
                  <div className="flex items-center mt-[30px]">
                    <Img
                      className="h-8 w-8 border rounded-full object-cover"
                      src="images/img_rectangle19.webp"
                      alt="..."
                    />
                    <div className="pl-[16px]">
                      <Text className="block text-black_900 font-semibold text-sm mx-auto">
                        Kenneth Allen
                      </Text>
                      <Text className="block text-gray-700 font-normal text-xs mx-auto">
                        Parent
                      </Text>
                    </div>
                  </div>
                </div>
                <Img
                  className="h-[101] w-[99] absolute bottom-0 right-0"
                  src="images/testmBgimage3.png"
                  alt="..."
                />
              </div> */}
            </Slider>
          </div>
        </div>
      </div>
    </>
  );
};
export default Testimonial;
